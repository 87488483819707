import React from 'react';
import Helmet from 'react-helmet';

import Layout from '../../layouts/SimplePage';
import FirstScreenSection from '../../components/FirstScreenSection';
import GradesSection from '../../components/GradesSection';
import Calculator from '../../components/Calculator';
import Testimonials from '../../components/Testimonials';
import OurFeaturesSection from '../../components/OurFeaturesSection';
import {
  SeoTextSection,
  SeoTextHeading,
  SeoTextContent,
} from '../../components/SeoText';
import CTA from '../../components/CTA';

const PsychologyWritingServicePage = () => (
  <Layout
    pageWrapperClassName="psychology-writing-service-page"
    headerClassName="header_white-bg"
  >
    <Helmet
      title="Psychology Essay Writing Service to Handle Your Learning Issues | TakeAwayEssay.com"
      meta={[
        {
          name: 'description',
          content:
            'We would like to highlight the main benefits you can reap if you collaborate with our psychology essay writing service. Place an order now to let us help you overcome all your hardships.',
        },

        {
          property: 'og:title',
          content:
            'Custom Psychology Essay Writing Service that Helps You with Your Studies | TakeAwayEssay.com',
        },
        {
          property: 'og:description',
          content:
            'Custom psychology essay writing service from the best specialists. Order now, the ordering process is simple and fast. Online writing services in any field. Availability day and night, urgent deadlines are manageable. Affordable prices.',
        },
        { property: 'og:site_name', content: 'Takeawayessay.com' },
        { property: 'og:type', content: 'website' },
        {
          property: 'og:image',
          content: 'https://takeawayessay.com/open-graph-img.jpg',
        },
      ]}
      link={[
        {
          rel: 'canonical',
          href: 'https://takeawayessay.com/psychology-writing-service.html',
        },
      ]}
    />

    <FirstScreenSection containerClassName="first-screen-section--seo">
      <h2 className="first-screen-section__title first-screen-section__title_seo">
        24/7 online support
        <br className="first-screen-section__title__br" /> for all clients
      </h2>
      <p className="first-screen-section__heading first-screen-section__heading_seo">
        We care about each and every customer
        <br className="first-screen-section__heading__br" /> who purchases an
        essay from us
      </p>
    </FirstScreenSection>

    <GradesSection />
    <Calculator />
    <Testimonials nameOfPageArray="home" />
    <OurFeaturesSection />

    <SeoTextSection>
      <SeoTextHeading>
        Psychology essay writing service to overcome all learning hardships
      </SeoTextHeading>

      <SeoTextContent>
        <div className="seo-text-section__text__css-half-column">
          <p>
            Who can write my psychology paper? This is a pretty popular question
            that can be met on the Internet. We don’t mean that requests for
            “psychology writing services” are the most popular. We mean that
            custom aid itself is in high demand. That is why we offer it for 11
            years in a row and enjoy tremendous success.
          </p>
          <p>
            Our writing company is called TakeAwayEssay.com. We are the choice
            number one of the thousands of US learners, as well as many
            international students. What are the reasons for our popularity? Our
            platform always puts the interests of its clients in the first
            place. That is why our clients are happy with what we do for you. If
            you continue to read, you will understand why.
          </p>
          <h2 className="h3">
            Get swift and affordable psychology essay writing help
          </h2>
          <p>
            When students look for psychology essay writing assistance, they
            give heed to various conditions, possible benefits, guaranteed
            conveniences, etc. One of them is surely the speed of execution.
            Most students hate to meet their deadlines. There are too many tasks
            and duties with strict time limits that should not be broken. At
            times, they have no physical ability to submit several assignments
            on time. This is when we help!
          </p>
          <p>
            Our prominent experts have excellent time management skills and know
            the best strategies. As soon as they see your demands, they already
            know how much time is required to deal with your order and what
            strategy to apply. If your conditions are realistic, your order will
            be ready before the deadline runs out!
          </p>
          <p>
            Another crucial point all learners check is the price. They have
            pretty short budgets and cannot afford too pricey essay writing
            service psychology. We are aware of this shortcoming and offer our
            flexible system of choosing the price that suits everyone’s budget.
            How does it work? You are to fill out the order form to let us know
            what way it must be solved. The most significant points and price
            indicators are as follows:
          </p>
          <ul className="dot-list">
            <li>The desired quality.</li>
            <li>Assignment type.</li>
            <li>Your time limit.</li>
            <li>The volume of the project.</li>
          </ul>
          <p>
            An online calculator will show the final bid. If it exceeds your
            budget, you are free to change any of these points to lower the
            price until it suits your financial possibilities. It’s a fast and
            logical method.
          </p>
          <p>
            The reputation of your writer means a lot too. You will surely
            receive several bids from our performers. Contact them to find out
            who suits you better. The higher his or her status is, the more fees
            will be charged. Yet, you can negotiate on the terms you like.
            Besides, all our experts are highly qualified. If the status isn’t
            too high, it doesn’t mean the expert’s aid is of low quality.
            Perhaps he/she is a newbie or takes a small number of orders to
            elevate reputation. Quality will be as high as you can.
          </p>
          <h2 className="h3">
            Use a high-quality psychology paper writing service
          </h2>
          <p>
            If you choose our psychology essay writing service, you never lose!
            Why are we that confident? We have professional writers with
            outstanding academic abilities. They sharpen those abilities to meet
            the top standards of every school, college, or university.
          </p>
          <p>
            Our psychology essay writing service undergraduate paper adjusts to
            the demands you set. We treat every customer personally. All your
            demands will be taken into account and fulfilled exactly as you
            want. Your order can be written, edited, outlined, rewritten, cited,
            and so on. We offer a wide range of experts with diplomas in various
            specialties:
          </p>
          <ul className="dot-list">
            <li>Math;</li>
            <li>Algebra;</li>
            <li>Geometry;</li>
            <li>Geography;</li>
            <li>History;</li>
            <li>Culture;</li>
            <li>Ethics;</li>
            <li>English;</li>
            <li>Medicine;</li>
            <li>Chemistry;</li>
            <li>Marketing;</li>
            <li>Finance, etc.</li>
          </ul>
          <p>
            You will find experts who handle all types of essays, as well as
            other assignments. You will be provided with all kinds of academic
            features.
          </p>
          <h2 className="h3">More advantages for you</h2>
          <p>
            Our custom psychology essay writing service would have never gotten
            so high if it didn’t cultivate other vital dividends and guarantees
            for its dear clients. They are surely essential and when we will
            describe them, you will understand why. Make allowances for the next
            advantages you will enjoy when you join us:
          </p>
          <ul className="dot-list">
            <li>
              Full Privacy
              <p>
                One of the most significant things every user checks is his or
                her online safety. No one wants to expose his or her private
                data to anyone else, especially to teachers because they may
                deprive students of many grades. Although our functioning is
                100% legal, educators are against our collaboration with you. We
                realize it and do whatever is necessary to protect your private
                data.
              </p>
              <p>
                Firstly, we pledge to never reveal any facts about you or your
                orders to somebody else. Secondly, we use effective software to
                protect our databases. Thirdly, we encrypt all transactions on
                our site.
              </p>
            </li>
            <li>
              Authentic Content
              <p>
                When you use our aid, you enjoy creative and authentic papers.
                They all are done from scratch. It means they are original and
                don’t contain any plagiarized elements. We use special checking
                tools to detect plagiarized elements and remove them from your
                texts.
              </p>
            </li>
            <li>
              All Formats
              <p>
                One of the most important psychology essay writing services is
                formatting. Most students hate to format their title pages, make
                reference lists, as well as insert direct citations. We can
                tackle these issues instead of them. Our specialists deal with
                APA, AMA, MLA, Chicago, Turabian, Harvard, ASA, Vancouver, and
                other popular academic writing formats.
              </p>
            </li>
            <li>
              A Refund Guarantee
              <p>
                As we are an honest custom company, we ensure the investments of
                our clients to the fullest. After your writer agrees to complete
                your demands, you risk nothing. In case your writer violates
                your agreement, we will return your money. Nonetheless, it’s
                unlikely to happen because we are professionals and do not fail
                our customers.
              </p>
            </li>
            <li>
              Responsive Customer Support
              <p>
                Finally, you can always count on our amazing customer support
                team. Our competent technicians are at work 24/7 to supervise
                our customers. If you don’t understand our policies or methods
                of work, they will be happy to provide you with the desired
                responses. As we are at work 24/7, you can also place instant
                orders whenever the need appears.
              </p>
            </li>
          </ul>
        </div>
      </SeoTextContent>
    </SeoTextSection>

    <CTA btnText="Hire our writers">
      Got a writing problem you cannot solve?
      <br /> We can help you.
    </CTA>
  </Layout>
);

export default PsychologyWritingServicePage;
